$white: #FFFFFF;
$grey_1: #F2F2F2;
$grey_2: #EDEDED;
$grey_3: #ECECEC;
$grey_4: rgba(0, 0, 0, 0.08);
$grey_5: rgba(51, 51, 51, 0.08);
$grey_6: #D8D8D8;
$grey_7: #747474;

$orange_1: rgba(214, 119, 8, 0.95);
$orange_2: #E28C28;
$orange_3: #E6A150;
$orange_4: #B9904D;
$orange_5: #EA9737;

$green_1: #007967;
$green_2: #02A78D;
$green_3: #4AAD9E;
$green_4: #3B9A8C;

$blue_1: #2980A0;
$blue_2: #276077;
$blue_3: #24588C;
$blue_4: #0C688A;
$blue_5: #0A5A77;
$blue_6: #579CB5;
$blue_7: #4B8FA1;
$blue_8: #d9e7ed;

$red: #DE2E2E;
$black: #1A1A1A;
$green: #014E43;
