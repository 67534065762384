/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import 'src/app/colors';

@font-face {
  font-family: MontserratSemiBold;
  src: url("assets/font/montserrat-semibold-webfont.woff") format("opentype");
}

@font-face {
  font-family: MontserratBold;
  src: url("assets/font/montserrat-bold-webfont.woff") format("opentype");
}

@font-face {
  font-family: MontserratRegular;
  src: url("assets/font/montserrat-regular-webfont.woff") format("opentype");
}

ion-header {
  ion-toolbar {
    border-top: 8px solid $blue_5;
    --background: #{$blue_4};
    --min-height: 48px;

    ion-icon {
      width: 10%;
      height: 20px;
      color: white;
      padding-left: 10px;
    }

    ion-title {
      width: 90%;
      text-align: center;
      color: white;
      font-family: MontserratSemiBold, sans-serif;
      font-size: 16px;
    }
  }
}

ion-icon {
  &[class*="custom-"] {
    mask-size: contain;
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
    background: currentColor;
    width: 1em;
    height: 1em;
    color: #0A5A77;
  }

  &[class*="custom-icon-dashboard"] {
    mask-image: url(assets/icon/bar-chart-outline.svg);
  }

  &[class*="custom-icon-create"] {
    mask-image: url(assets/icon/create.svg);
  }

  &[class*="custom-icon-person"] {
    mask-image: url(assets/icon/person-outline.svg);
  }

  &[class*="custom-icon-people"] {
    mask-image: url(assets/icon/people-outline.svg);
  }

  &[class*="custom-chevron-down"] {
    mask-image: url(assets/icon/chevron-down-outline.svg);
    color: $orange_5;
  }

  &[class*="custom-chevron-up"] {
    mask-image: url(assets/icon/chevron-up-outline.svg);
    color: $orange_5;
  }

  &[class*="custom-icon-rivp"] {
    mask-image: url(assets/icon/rivp.svg);
    color: white;
    padding-left: 5px;
    width: 65px;
    height: 32px;
  }

  &[class*="custom-icon-user"] {
    mask-image: url(assets/icon/user.svg);
    color: white;
  }

}

ion-content {
  --ion-background-color: #{$blue_8};
}

ion-card {
  --ion-background-color: #{$white};
  border-radius: 10px;
}

.text-bold {
  font-weight: bold;
}

.alert-lg {
  --width: 300px;
  --max-width : 300px;
}
